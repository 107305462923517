









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'navLayout'
})
export default class InsNavLayout2 extends Vue {
  @Prop() private navData!: object;
  To (n) {
    if (n.RedirectType !== undefined && n.RedirectType !== -1) return this.To({ Type: n.RedirectType, Value: { Id: n.RedirectValue }, Url: n.RedirectValue });
    return n.Type === 0 ? n.Url : n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '/';
  }
}
